*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto";
}

.general-container{
  display: block;
  width:100%;
  height: 92vh;
  margin: 0 auto;
  padding: 0; 
}

.page-container{
  display: block;
  width:100%;
  height: 92vh;
  margin: 10px auto;
  padding: 10px;
  overflow-y: hidden;
} 

footer{
  display: block;
  background-color: lightgray;
  width:100%;
  /* height: 230px; */
  height: 300px;
  margin: 0 auto;
  padding: 0;
}

.map-container {
  height: 91vh;  
}

.map-layer-grid{
  height: 1px!important;
} 

#map-layer-drawer{
  height: 35px!important;
}

.drawer-background{
  background-color: rgba(226, 196, 196, 0.5);
}

.drawerTopText{
  text-align: center;
  padding: 10px;
  position: relative;
}

.drawerTabSmallText{
  display: block;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  height: 16px;
  font-size: 11px;
}

.drawerColumn{
  padding-left: 5px!important;
  text-align: center;
}

.drawerColumnContainer{  
  margin: 5px;
  padding: 5px ;
  border-left: 1px solid black;
  overflow-y: auto; 
  text-align: left;
  max-height: calc(90vh - 135px);
} 

.drawerColumnContainerCo{   
  padding: 20px 5px 5px 5px!important; 
  max-height: calc(90vh - 158px)!important;
} 

.searchDrawerButtonVisible{
  visibility: hidden!important; 
}

.visibleSearchLoading{
  visibility: visible!important;
}

.resultsMessageVisibleStyle{
  display: block!important;
} 

#legendHook{
  position: absolute;
  top: 591px;
  right: 0px;
  width: 50px;
  height: 1px;
  z-index: 7;
  visibility: hidden;  
}

#measureHook{
  position: absolute;
  top: 373px;
  right: 0px; 
  width: 50px;
  height: 50px;
  z-index: 200; 
  visibility: hidden; 
}

#measureBox{
  margin: 0px 10px 10px 10px;
  padding: 5px;
  width: 270px; 
  text-align: center;
}

#measureTitle{
  padding: 5px;
}

#legendBox{
  width: 206px; 
}

.legendRow{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legendImage{
  width: 24px;
  height: 24px;
  margin-right: 5px;
}


#map-layers-button{
  bottom: -35px;
  z-index: 1; 
  min-width: 24px !important;
  background-color: white;
} 

#map-angle-button{
  bottom: -178px;
  z-index: 1; 
  min-width: 24px !important;
  background-color: white;
} 

#map-legend-button{
  bottom: -251px;
  z-index: 1; 
  min-width: 24px !important;
  background-color: white;
} 
 

#map-list-button{
  position: absolute;
  top: 12px;
  left: 12px; 
  min-width: 24px !important;
  background-color: white;
}

.mapboxgl-popup-content{
  background:rgba(255,255,255,0.5)!important;
}

.mapboxgl-popup-tip{
  background:rgba(255,255,255,0.5)!important;
}

.btn-parts-content{
  min-height: 60px;
  max-height: 100px; 
  overflow-y: scroll;
}

.btn-contexts-content{
  min-height: 70px;
  max-height: 120px; 
  overflow-y: scroll;
}

.btn-contexts-content{
  min-height: 85px;
  max-height: 230px; 
  overflow-y: scroll;
}

.btn-popup{
  margin: 2px auto;
  padding: 2px;
  cursor: pointer;
  display: block;  
  width: 100%;
}

.btn-excavations-popup{
  width: 100%;
  background: rgba(255, 0, 0, 0.2);
}

.btn-parts-popup{ 
  background: rgba(0, 255, 0, 0.2);
}

.btn-contexts-popup{
  background: rgba(0, 0, 255, 0.2);
}

.btn-excavations-popup:hover{
  background: rgba(255, 0, 0, 0.5);
}

.btn-parts-popup:hover{
  background: rgba(0, 255, 0, 0.5);
}

.btn-contexts-popup:hover{
  background: rgba(0, 0, 255, 0.5);
}

.mapboxgl-popup-tip{
  display: none!important;
}

.btn-contexts-showAll{
  margin: 4px auto;
  width: 100%;
}

#menuBox{
  width: 160px;
  height: 160px;
  padding: 5px;
}

.menuitem{
  display: block!important;
}

.menuitemtitle{
  position: absolute;
  right: 16px;
  top: -1px;
}


.menuTitleEx{
  color: red;
}
.menuTitlePa{
  color: green;
}
.menuTitleCo{
  color: blue;
}

#aboutcontainer{
  width:75%; 
  margin:0 auto;
}

.aboutheader{
  text-align:center;
  margin: 10px!important;
}

@media only screen and (max-width: 975px) { 

  #aboutcontainer{
    width:90%;  
  }
}

@media only screen and (max-width: 810px) {
  #homeTitle{
    font-size: 3rem;
    bottom: 150px;
    right: 25px;
  }

  #homeSubtitle{
    font-size: 1rem;
    right: 25px;
  }

  #aboutcontainer{
    width:97%;  
  }
}

#exInfoHook{ 
  position:absolute;
  top: 110px;
  width: 10px;
  height: 10px;
}

@media only screen and (min-width: 1600px) {
  #exInfoHook{
    right:50%;   
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1599px) {
  #exInfoHook{
    right:35%;   
  }
}

@media only screen and (max-width: 1249px) {
  #exInfoHook{
    right:25px;   
  }
}

.featureInfoLongText{
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 14px;
  margin: 5px 0;
  padding: 5px 0;
}

.featureInfoText{ 
  font-size: 14px;
  margin: 5px 0;
  padding: 5px 0;
}

.featureInfoAirText{
  font-size: 14px;
  margin: 5px 0;
  padding: 5px 0;
}

.featureInfoLongestText{
  height: 68vh;
  overflow-y: auto;
  font-size: 14px;
  margin: 5px 0;
  padding: 5px 0;
}

.featureInfoSubTitle{
  display: block; 
  padding: 8px;
  background-color: lightgray;
  font-weight: bold;
  font-size: 17px;
}

.hideExtrudeAllButton{
  display: none!important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  position: absolute;
  right: 0!important;
  bottom: 0!important;
  margin: 0!important;
}

.css-ahj2mt-MuiTypography-root{
  font-size: 11px!important;
}

#expoptext{
  font-size: 11px!important;
}

#expoptextClick{
  font-size: 11px!important;
  margin-left: 5px;
}

.css-9l3uo3{
  font-size: 11px!important;
}

.css-j204z7-MuiFormControlLabel-root{
  margin: 0!important;
  padding: 0 5px!important;
}

.css-wudn58{
  padding: 2px 4px!important;
}

.backdropContent{
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
}


.highlightArrow{
  background-color: gray!important;
}

#cookiesContainer{
  background-color: rgba(211, 211, 211, 0.6);
  margin: 0px;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed;
}

#cookiesContainerInside{ 
  padding: 5px 10px; 
  font-size: 14px;
  display: block;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

#cookiesContainerInside button{
  margin: 0 5px;
  color: darkblue;
}

.hideExPop{
  display: none!important;
}

#terms-container{
  height: 80vh;
  overflow-y: auto;
}

.weightCountSum{
  text-align: center;
}

.zooCountSum{
  text-align: center;
  width: 50%;
  margin-left: 50px;
}

.css-19kzrtu {
  padding: 0 24px 24px 24px!important;
}


.seasonimage{
  display: block;
  width: 65%;
  height: auto;
  margin: 0 auto;
  padding: 5px;
}

.seasonimage:hover{
  cursor: pointer;
}

.seasoncaption{
  margin: 0 auto;
  padding: 0 5px 5px 5px;
  width: 100%;
  display: block;
  text-align: center;
  font-size: 11px;
}



#rcc-decline-button{
  background: grey!important; 
  color: lightgrey!important;
  font-size: 15px;
}

#rcc-confirm-button{
  background: grey!important; 
  color: lightgrey!important;
  font-size: 15px;
}
